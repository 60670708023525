.sub-heading {
    text-align: center;
    padding: 2rem;
    margin-top: 1rem;
  }

  .sub-heading h1{
    font-weight: bold;
  }
  
  .intro-text {
    font-size: 1.1rem;
    margin: 1rem auto;
    max-width: 90%;
  }
  
  .steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .sub-heading-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 200px;
    max-width: 250px;
    margin: 1rem;
    text-align: center;
  }
  
  .sub-heading-icon {
    width: 60px;
    height: 60px;
  }
  
  .sub-heading-heading {
    font-size: 1.5rem;
    margin: 0.5rem 0;
    font-weight: bold;
  }
  .sub-text{
    text-align: justify;
  }
  .sub-heading-description {
    font-size: .9rem;
    color: #555;
    align-items: center;
    text-align: center;
    font-weight: lighter;
  }

  .notch-section{
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  .notch-right div{
    margin: 1.5rem;
  }
  .notch-right span{
    margin-left: 10px;
  }
  @media screen and (max-width: 650px) {
    .sub-heading {
        justify-content: center;
        align-items: center;
    }
    .notch-section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
   .custom-mob{
    font-size: 1.8rem;
   }
    
  }