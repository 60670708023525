.main-container h2 {
  text-align: center;
  padding-top: 40px;
}
.sub-paragraph {
  text-align: justify;
  padding-top: 20px;
  color: gray;
  width: 50%;
  line-height: 30px;
}
.card-heading-service {
  text-align: center;
  font-size: 1.5rem;
  font-family: "Lucida Sans", "Lucida Grande", "Lucida Sans Unicode", sans-serif;
  padding-top: 50px;
}

.card-heading-service .heading-underline-service {
  position: relative;
  display: inline-block;
}
.upcards {
  margin-top: -10rem !important;
}
.card-heading-service .heading-underline-service::after {
  content: "";
  margin-top: 50px;
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 50%;
  height: 3px;
  background-color: rgb(220, 166, 66);
}
.webpan-cards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding-top: 10px;
}
.last-paragraph {
  padding-top: 1.5rem;
  padding-bottom: 30px;
  text-align: justify;
  color: gray;
}

.webanimation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  overflow: hidden;
}
.horizontal-fram-web {
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 20px; */
  width: 100%;
}

@media (max-width: 992px) {
  .horizontal-fram-web {
    flex-direction: column;
  }
  /* .sub-paragraph {
    font-size: 12px;
  } */
}

.text p{
  color: #808080;
  font-size: 20px;
}