.step {
    text-align: center;
    padding: 2rem;
  }

  .step h1{
    font-weight: bold;
  }
  
  .intro-text {
    font-size: 1.1rem;
    margin: 1rem auto;
    max-width: 90%;
  }
  
  .steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .step-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 200px;
    max-width: 250px;
    margin: 1rem;
    text-align: center;
  }
  
  .step-icon {
    width: 60px;
    height: 60px;
  }
  
  .step-heading {
    font-size: 1.5rem;
    margin: 0.5rem 0;
    font-weight: bold;
  }
  
  .step-description {
    font-size: .9rem;
    color: #555;
    align-items: center;
    text-align: center;
    font-weight: lighter;
  }
  @media screen and (max-width: 650px) {
    .step {
        justify-content: center;
        align-items: center;
    }
   
    
  }