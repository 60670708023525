.csd-hero {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row-reverse;
}

.main-paragrah-csd {
  width: 50%;
  line-height: 1.8 !important;
  margin-top: 20px;
}

.cards-container-csd {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.csd-single-card {
  width: 40% !important;
  background-color: white;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 50px;
  margin-top: 20px;
}

.scs-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  flex-direction: column;
  margin-top: 30px;
  padding: 40px;
}

.scs-card h2 {
  font-weight: bold;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 10px;
}

.scs-cards {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 25%;
}

.cards-container-software {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

/* button */
.buttons {
  display: flex;
  justify-content: flex-start;
  top: 20px;
}

.buttons button {
  width: 150px;
  height: 50px;
  background-color: white;
  margin-top: 20px;
  color: #f3972b;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border-color: #f3972b;
}

.btn {
  border-color: #f3972b;
}

.buttons button:before,
.buttons button:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #f3972b;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button:before {
  right: 0;
  top: 0;
  transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button:after {
  left: 0;
  bottom: 0;
}

.buttons button span {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.buttons button span:before,
.buttons button span:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 0;
  background-color: #f3972b;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button span:before {
  right: 0;
  top: 0;

  transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons button span:after {
  left: 0;
  bottom: 0;
}

.buttons button p {
  padding: 0;
  margin: 0;
  transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
  position: absolute;
  width: 100%;
  height: 100%;
}

.buttons button p:before,
.buttons button p:after {
  position: absolute;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
  z-index: 1;
  left: 0;
}

.buttons button p:before {
  content: attr(data-title);
  top: 50%;
  transform: translateY(-50%);
}

.buttons button p:after {
  content: attr(data-text);
  top: 150%;
  color: #f3972b;
}

.buttons button:hover:before,
.buttons button:hover:after {
  width: 100%;
}

.buttons button:hover span {
  z-index: 1;
}

.buttons button:hover span:before,
.buttons button:hover span:after {
  height: 100%;
}

.buttons button:hover p:before {
  top: -50%;
  transform: rotate(5deg);
}

.buttons button:hover p:after {
  top: 50%;
  transform: translateY(-50%);
}

.buttons button.start {
  background-color: #f3972b;
  box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.buttons button.start p:before {
  top: -50%;
  transform: rotate(5deg);
}

.buttons button.start p:after {
  color: white;
  transition: all 0s ease;
  content: attr(data-start);
  top: 50%;
  transform: translateY(-50%);
  animation: start 0.3s ease;
  animation-fill-mode: forwards;
}

@keyframes start {
  from {
    top: -50%;
  }
}

.buttons button.start:hover:before,
.buttons button.start:hover:after {
  display: none;
}

.buttons button.start:hover span {
  display: none;
}

.buttons button:active {
  outline: none;
  border: none;
}

.buttons button:focus {
  outline: 0;
}
.CSD-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58%;
  height: 100%;
  overflow: hidden;
}

/* /////////////////////////////////////////////////// */

@media (max-width: 992px) {
  .csd-hero {
    flex-direction: column-reverse;
  }
  .csd-hero-img {
    width: 100%;
  }

  .main-paragrah-csd {
    width: 100%;
  }

  .cards-container-csd {
    flex-direction: column;
  }

  .csd-single-card {
    width: 80% !important;
  }

  .scs-card {
    flex-direction: column;
  }

  .scs-cards {
    width: 50%;
  }
}

@media (max-width: 500px) {
  .scs-cards {
    width: 100%;
  }
}
