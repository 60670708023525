.card-heading-service {
  text-align: center;
  font-size: 1.5rem;
  font-family: "Lucida Sans", "Lucida Grande", "Lucida Sans Unicode", sans-serif;
  padding-top: 80px;
}

.card-heading-service .heading-underline-service {
  position: relative;
  display: inline-block;
}

.card-heading-service .heading-underline-service::after {
  content: "";
  margin-top: 50px;
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 50%;
  height: 3px;
  background-color: rgb(220, 166, 66);
}

.paragraph {
  font-size: 1.2rem;
  padding-top: 20px;
  text-align: center;
}
.cards-container{
  display: flex;
  flex-direction: row !important;
}

.portfolio-container{
  flex-direction: column !important;
}

.pContainer {
  font-size: 1.3rem;
  padding-top: 15px;
  text-align: justify;
  width: 100%;
}

.pContainer2 {
  font-size: 1.3rem;
  width: 50%;
}

.service-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  gap: 30px;
}

.paragraph2 {
  font-size: 1.2rem;
  padding-top: 50px;
  text-align: center;
}

.web-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 30px;
  gap: 30px;
}

.contact-btn {
  border: none;
  background-color: #4599b4;
  padding: 12px 20px;
  border-radius: 30px;
  color: white;
  margin-top: 15px;
}

.contact-btn:hover {
  background-color: rgb(220, 166, 66);
  transition: 1s ease;
}

.ourwork-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  overflow: hidden;
}

.text-img-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

@media (max-width: 992px) {
  .pContainer2, .ourwork-animation {
    width: 100%;
  }
  .text-img-cont {
    padding-top: 50px;
  }
 
}

 

