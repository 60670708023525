.portfolio-detail-page {
  padding: 50px;
  background-color: #f8f9fa;
  text-align: center;
}

.portfolio-header {
  font-size: 2.5rem;
  color: #222;
  margin-bottom: 20px;
}

.portfolio-intro {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 40px;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three cards per row */
  gap: 20px;
  justify-items: center;
}

@media (max-width: 992px) {
  .portfolio-grid {
    grid-template-columns: repeat(2, 1fr); /* Two cards per row on medium screens */
  }
}

@media (max-width: 600px) {
  .portfolio-grid {
    grid-template-columns: 1fr; /* Single column on small screens */
  }
}

.portfolio-card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Softer shadow */
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 370px;
  padding: 15px;
  padding: 15px;
  position: relative;
}

.portfolio-card:hover {
  transform: translateY(-5px); /* Subtle lift effect */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.portfolio-img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 15px; /* Match the card's border radius */
  transition: transform 0.3s ease;
}

.portfolio-info {
  padding: 20px;
  text-align: left;
}

.portfolio-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.portfolio-description {
  font-size: .9rem;
  color: #555;
  margin-top: 8px;
  font-weight: lighter;
  color: rgb(145, 144, 144);
}
