.WhyUsHero {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}
.whyUsPara {
  color: #000000;
  text-align: center;
  padding: 50px 20px 10px 20px;
}

.lottie-containerwhyus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .whyUsPara {
    font-size: 1.2rem;
  }
}
