@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");
.upcards {
  margin-top: -10rem !important;
}
.plans-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 40px 0;
}

.plan-card {
  max-width: 320px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  /* background-color: #fff; */
  /* border: 1px solid #e0e0e0; */

  background: linear-gradient(white, white) padding-box,
    linear-gradient(145deg, transparent 0%, #eca553, #4599b4) border-box;
  border: 4px solid transparent;
  background-size: 200%;
  animation: gradient 5s ease infinite !important;
}

.plan-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.plans-cont {
  text-align: center;
  padding: 20px;
  padding-top: 20px;
}

.plan-header {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.plan-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.plan-price {
  font-size: 20px;
  color: #555;
  margin-bottom: 20px;
}

.select-button {
  padding: 10px 20px;
  background-color: #4599b4;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 16px;
  font-weight: bold;
}

.select-button:hover {
  background-color: #f3972b;
}

.plan-features-container {
  padding: 0 20px;
}

.plan-features {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #555;
  text-align: left;
}

.plan-features li {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.plan-features li i {
  color: #4599b4;
  margin-right: 10px;
}
.logoPlans {
  max-width: 260px;
  height: 590px;
}
.mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  overflow: hidden;
}

.text-img-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  margin-top: -50px;
  margin-bottom: -50px;
  flex-wrap: wrap;
}

.sub-paragraph {
  width: 50% !important;
}

@media (max-width: 992px) {
  .text-img-cont {
    flex-direction: column;
  }
  .sub-paragraph {
    width: 100% !important;
  }
  .mobile {
    width: 100%;
  }
}
