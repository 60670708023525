.portfolio-card {
  width: 330px;
  border: none;
  border-radius: 15px;
  padding: 30px;
  /* margin: 15px; */
  background: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.icon-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgb(232, 229, 229);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.icon-container img {
  width: 60%;
  height: 60%;
  object-fit: contain;
}



.card-title {
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  /* margin: 10px 0; */
  /* text-align: left; */
  width: 100%;
}

.card-desc {
  font-size: .9rem;
  color: #666;
  margin-top: 5px;
  /* text-align: left; */
}

/* Cards Container */
.service-cards-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

/* Responsive Design */
@media (max-width: 768px) {
  .service-cards-grid {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .portfolio-card {
    width: 90%;
    margin: 10px 0;
  }
}
