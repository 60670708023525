.lottie-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  height: auto;
  overflow: hidden;
}

@media (max-width: 600px) {
  .weblottie-container {
    max-width: 400px;
  }
}

@media (max-width: 400px) {
  .weblottie-container {
    max-width: 400px;
  }
}
