.main-container {
  padding-top: 50px;
  /* text-align: center; */
  word-spacing: 2px;
}
.card-heading-service {
  text-align: center;
  font-size: 1.5rem;
  font-family: "Lucida Sans", "Lucida Grande", "Lucida Sans Unicode", sans-serif;
  padding-top: 30px;
}
.custom-text {
  margin-left: 10px;
}

.card-heading-service .heading-underline-service {
  position: relative;
  display: inline-block;
}
.main-para{
  text-align: justify;
  color: gray;
  line-height: 30px;
}

.card-heading-service .heading-underline-service::after {
  content: "";
  margin-top: 50px;
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 50%;
  height: 3px; /* Adjust the height as needed */
  background-color: rgb(220, 166, 66);
}
.paragraph {
  text-align: justify;
  font-size: 1rem;
}
.main-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  gap: 20px;
}
.modalbtn {
  text-align: center;
  padding-top: 30px;
  background-color: none;
}

.typewriter {
  display: block;
  overflow: hidden; /* Ensures the text is clipped to the animation */
  border-right: 0.15em solid orange; /* Adds a caret */
  white-space: nowrap; /* Prevents the text from wrapping */
  animation: typing 4s steps(40, end), blink-caret 0.75s step-end infinite;
}

.DM-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.horizontal-fram-digital{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding-top: 40px;
}


@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 70%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

@media screen and (max-width: 600px) {
  .main-heading {
    text-align: justify;
  }
  .paragraph {
    text-align: justify;
  }
  
}


@media (max-width: 992px) {
  .horizontal-fram-digital{
    flex-direction: column;
  }
}