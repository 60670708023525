
@font-face {
  font-family: Poppins;
  src: url("./assets/fonts/Poppins-Medium.ttf");
}

* {
  font-family: Poppins;
}


body {
  background: #f1f3f299;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


