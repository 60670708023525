@font-face {
  font-family: PoppinsBold;
  src: url("../assets/fonts/Poppins-Bold.ttf");
}
/* .lottie {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
} */
.rightanimation {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 450px; */
  width: 50%;
  height: 100% !important;
  /* height: 450px; */
  /* overflow: hidden;
  position: relative; */
  z-index: 1000;
  /* margin-left: -235px; */
}

/* .leftglobe-container {
  display: flex;
  width: 392px;
} */
.hero-image {
  /* background-image: url("../assets/images/bg2.jpg"); Path to your background image */
  background-image: url("../assets/background-image.jpeg"); /* Path to your background image */
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  /* background-repeat: no-repeat;  */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  position: relative;
  overflow: hidden;
  user-select: none;
  position: relative;
  /* z-index: -1; */
}
.heroContainer {
  /* z-index: 1; Ensure content is above the gradient */

  
  /* background-color: #f3972b; */
  /* gap: 80px; */
  width: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center;
 
}

.leftGlobe {
  position: absolute;
  top: 5%;
  left: 7%;
  /* transform: translate(-30%, -30%); */
  /* animation: moveDownUp 3s ease-in-out infinite alternate; */
  z-index: 1000;
}
.rightGlobe {
  position: absolute;
  top: 60%;
  left: 80%;
  transform: translate(-50%, -50%);
  animation: moveUpDown 3s ease-in-out infinite alternate;
  z-index: 1000;
}

.gear {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.market {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: white;
  text-align: center;
  margin: 0 0px;
  width: 50%;
}

.hero span {
  font-weight: bold;
  font-family: PoppinsBold;
}

.hero-btn:hover {
  background-color: #4599b4;
}

.hero-btn {
  border-radius: 50px;
  color: #ffffff;
  background-color: #f3972b;

  border: none;
  font-weight: bold;
  /* background-color: "#FF6F00"; */

  border: none;
  border-radius: 25px;
  padding: 15px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

/* Style for the first span */
.first-line {
  /* font-family: "'Roboto', sans-serif"; */
  font-weight: 300;
  font-size: 2.3rem;
  color: #f3972b;
  display: flex;
  align-items: center;
}
.technology {
  color: #f3972b;
  font-weight: 700;
  display: flex;
  align-items: center;
}

/* Style for the second span */
.second-line {
  color: #4599b4;
  /* font-size: 4rem; Default font size */
  /* font-family: "'Roboto', sans-serif"; */
  font-weight: 300;
  font-size: 2.3rem;
  /* color: black; */
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: -10px;
}
.marketing {
  font-size: 1.8rem !important;
  color: #4599b4;
  font-weight: 700;
  display: flex;
  align-items: flex-end;
}

/* Responsive styles */
@media (max-width: 1210px) {
  
  .first-line {
    font-size: 1.8rem;
    flex-direction: column;
    font-weight: 700;
  }
  .second-line {
    font-size: 1.8rem;
    flex-direction: column;
    align-items: center;
    gap: 0;
    font-weight: 700;
  }
  .hero-btn-div {
    padding: 0;
  }
  .hero {
    align-items: center;
    /* margin-top: 80px; */
  }
  .technology {
    flex-direction: column;
  }
  .marketing {
    flex-direction: column-reverse;
  }
}

@media (max-width: 768px) {
  .rightanimation {
    display: none;
  }
  .first-line {
    font-size: 2.5rem;
  }
  .second-line {
    font-size: 2.5rem;
  }
  .hero-btn-div {
    padding: 0;
  }
  .heroContainer {
    flex-direction: column;
    justify-content: center;
  height: 100vw;
  }
  .hero {
    align-items: center;
    /* margin-top: 100px; */
    margin-left: 0;
  }
  .rightanimation {
    margin: 0;
    /* width: 350px;
    height: 350px; */
    margin-top: 0px;
  }
}
@media (max-width: 478px) {
  .first-line {
    font-size: 2rem;
  }
  .second-line {
    font-size: 2rem;
  }
  .hero-btn {
    padding: 10px 20px;
    font-size: 1rem;
  }
  /* .hero {
    margin-top: 70px;
  } */
  .heroContainer {
    flex-direction: column;
    justify-content: center;
 
  }
}

@media (max-width: 395px) {
  .first-line {
    font-size: 1.8rem;
    flex-direction: column;
    font-weight: 700;
  }
  .second-line {
    font-size: 1.8rem;
    flex-direction: column;
    align-items: center;
    gap: 0;
    font-weight: 700;
  }
  .hero-btn-div {
    padding: 0;
  }
  .hero {
    align-items: center;
    /* margin-top: 80px; */
    
  }
  .hero-image{
    height: 900px;
  }
  .technology {
    flex-direction: column;
  }
  .marketing {
    flex-direction: column-reverse;
  }
}
