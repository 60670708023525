.anime {
  background-image: url("data:image/svg+xml,%3Csvg%20id%3D%22sw-js-blob-svg%22%20viewBox%3D%220%200%20100%20100%22%20xmlns%3D%22http://www.w3.org/2000/svg%22%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22sw-gradient%22%20x1%3D%220%22%20x2%3D%221%22%20y1%3D%221%22%20y2%3D%220%22%3E%3Cstop%20id%3D%22stop1%22%20stop-color%3D%22rgba(69%2C%20153%2C%20180%2C%201)%22%20offset%3D%220%25%22%3E%3C/stop%3E%3Cstop%20id%3D%22stop2%22%20stop-color%3D%22rgba(71.001%2C%20154.911%2C%20181.882%2C%200.64)%22%20offset%3D%22100%25%22%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath%20fill%3D%22url(%23sw-gradient)%22%20d%3D%22M18.9%2C-34.8C24.1%2C-29.7%2C27.7%2C-23.9%2C31.9%2C-18C36%2C-12.1%2C40.7%2C-6%2C42.2%2C0.9C43.7%2C7.7%2C41.9%2C15.5%2C37.5%2C20.9C33.1%2C26.4%2C26%2C29.5%2C19.3%2C32.2C12.6%2C35%2C6.3%2C37.3%2C0.4%2C36.6C-5.4%2C35.9%2C-10.9%2C32%2C-17.5%2C29.3C-24.2%2C26.5%2C-32.1%2C24.8%2C-36.5%2C20.1C-40.9%2C15.4%2C-41.7%2C7.7%2C-41.5%2C0.1C-41.2%2C-7.4%2C-39.8%2C-14.8%2C-35.5%2C-19.5C-31.1%2C-24.2%2C-23.7%2C-26.2%2C-17.3%2C-30.6C-10.9%2C-35%2C-5.4%2C-41.7%2C0.7%2C-43C6.8%2C-44.2%2C13.7%2C-39.8%2C18.9%2C-34.8Z%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20transform%3D%22translate(50%2050)%22%20stroke-width%3D%220%22%20style%3D%22transition%3A%20all%200.3s%20ease%200s%3B%22%20stroke%3D%22url(%23sw-gradient)%22%3E%3C/path%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
  width: 90%;
  height: 70%;
  /* background-color: #454545; */
}
.anime-container {
  background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%20200%20200%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill%3D%22%234599B4%22%20d%3D%22M61.4%2C-39.7C72.3%2C-35.2%2C69%2C-10.1%2C63.1%2C13.9C57.2%2C37.9%2C48.7%2C60.8%2C37.4%2C59.2C26%2C57.5%2C11.7%2C31.4%2C-4.5%2C19.7C-20.6%2C7.9%2C-38.7%2C10.4%2C-50.2%2C0C-61.7%2C-10.4%2C-66.7%2C-33.8%2C-57.3%2C-38C-47.8%2C-42.2%2C-23.9%2C-27.2%2C0.7%2C-27.7C25.2%2C-28.2%2C50.4%2C-44.3%2C61.4%2C-39.7Z%22%20transform%3D%22translate(100%20100)%22/%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 70%;
  /* background-color: #454545; */
}

.anime2 {
  flex-direction: row !important;
}

.anime-h5,
.anime-img {
  width: 40%;
}

.anime-card {
  width: 45% !important;
}

.anime-card2 {
  max-height: 35rem;
  height: 22rem;
  display: flex;
  align-items: center;
}

.animePortfolio {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px) {
  .anime-h5,
  .anime-img {
    width: 100%;
  }

  .anime-card {
    width: 80% !important;
  }

  .anime2 {
    flex-direction: column !important;
  }
}

@media (max-width: 400px) {
  .anime-card2 {
    height: 30rem !important;
  }
}

.gallery {
  padding: 16px;
  box-sizing: border-box;
}

.gallery img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}
