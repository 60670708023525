.about-us {
  padding: 20px;
}
.joinus {
  margin-top: 29px;
}

.about-header {
  text-align: center;
  margin-bottom: 40px;
}

.about-header h2 {
  padding-top: 50px;
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.about-header p {
  font-size: 1em;
  color: gray;
  text-align: justify;
}

.timeline {
  position: relative;
  margin: 20px 0;
}

.timeline::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #ddd;
  transform: translateX(-50%);
}

.timeline-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  position: relative;
}

.timeline-item.journey,
.timeline-item.ideas {
  padding-top: 90px;
}

.timeline-item:nth-child(odd) {
  flex-direction: row-reverse;
}

.timeline-item::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 15px;
  width: 10px;
  height: 10px;
  background: #ddd;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.timeline-img,
.timeline-content {
  width: 45%;
}

.timeline-img img {
  width: 70%;
  height: auto;
  border-radius: 10px;
}

.timeline-img.ideas {
  padding-top: 20px;
}

.timeline-content h3 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.timeline-content p {
  font-size: 1.2rem;
  color: #666;
  text-align: justify;
}

.timeline-content.ideas {
  padding-top: 20px;
}

.why-us {
  text-align: center;
  margin-top: 60px;
  padding-bottom: 20px;
}

.why-us h2 {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
}

.why-us p {
  font-size: 1.2em;
  color: gray;
  margin: 0 auto;
  width: 80%;
  text-align: justify;
}
.ABM-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ABP-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ABJ-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/* Responsive Design */
@media (max-width: 768px) {
  .timeline-content h3,
  p {
    padding-left: 18px;
  }

  .timeline-img {
    display: flex;
    justify-content: center;
    width: 55%;
  }

  /* .timeline-img img {
    width: 55%;
    
  } */

  .timeline {
    padding: 0;
  }

  .timeline-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .timeline-item:nth-child(odd) {
    flex-direction: column;
  }

  .timeline-img,
  .timeline-content {
    width: 100%;
  }

  .timeline-item::before {
    left: 1px;
  }

  .timeline::before {
    left: 1px;
  }

  .why-us p {
    width: 100%;
  }
}
