.footer {
  background-color: #4599b4;
  color: rgb(238, 238, 238);
  padding: 40px 20px;
  text-align: left;
  margin-top: 2rem;
}
.softmark {
  position: relative;
  display: block;
  text-align: center;
  font-size: 6rem;
  font-weight: bold;
  color: #4599b4;
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 8px;
  z-index: 1;
  margin-top: -9.65rem;
  user-select: none;
}
.findUs{
  color: #fff;
}
@keyframes smoke {
  0% {
    opacity: 0;
    transform: translateY(0) scale(0.5);
  }
  50% {
    opacity: 0.7;
    transform: translateY(-50px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(-100px) scale(1.5);
  }
}

.footer-top {
  display: flex;
  justify-content: center;
  /* align-items: flex-start; */
  margin-bottom: 20px;
}

.footer-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 20px;
}

.footer-left {
  position: relative;
  padding-left: 15px; /* Adjust padding as needed */
}

.footer-left::before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  height: 97%; /* Adjust height as needed */
  width: 1px; /* Adjust thickness of the border */
  background-color: #ffffff; /* Adjust border color */
}

.footer-center {
  position: relative;
  padding-left: 15px;
  line-height: 2rem;
  /* border-left: 2px solid #ffffff;
  border-radius: 10px; */
}
.footer-center::before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  height: 97%; /* Adjust height as needed */
  width: 1px; /* Adjust thickness of the border */
  background-color: #ffffff; /* Adjust border color */
}

.footer-bottom ul {
  list-style: none;
  padding: 0;
}

.footer-bottom ul li {
  margin: 10px 0;
}
.footer-right p {
  color: #ffff;
  text-align: left;
  cursor: 'default';
  font-size: 1rem;
}
.footer-right{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer-bottom ul li button {
  background: none;
  color: rgb(253, 248, 248);
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer-bottom ul li button:hover {
  text-decoration-thickness: 1.2px;
  color: rgb(250, 224, 140);
}

.footer-contact {
  /* margin-bottom: 20px; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
 
}
.footer-contact img {
  margin-bottom: 20px;
  width: 50px;
}

.footer-social {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.footer-social a {
  color: rgb(255, 253, 253);
  margin: 0 10px;
  font-size: 24px;
  opacity: 1;
  text-decoration: none;
}

.footer-social a:hover {
  color: rgb(71, 241, 227);
}

.footer-social p {
  margin-left: 10px;
  font-size: 1.2rem;
  color: #f3f2ef;
}

.transparent-contact-form {
  /* margin-top: 20px; */
  text-align: center;
}

.transparent-contact-form h4 {
  margin-bottom: 15px;
  color: #fff; /* Text color for the heading */
}

.form-transparent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.form-transparent input,
.form-transparent textarea {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border: 1px solid white;
  background: transparent;
  color: #fff;
  font-size: 16px;
  outline: none !important;
}

.form-transparent input::placeholder,
.form-transparent textarea::placeholder {
  color: rgba(255, 255, 255, 0.7); 
}

.submit-transparent-button {
  text-align: left;
  padding: 10px 20px;
  background-color: #176dc2;
  color: #ffff; /* Blue text for the button */
  border: 2px solid #ffff;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-transparent-button:hover {
  background-color: rgb(220, 166, 66);
  color: #fff;
}
/* .contact-form-footer{
  padding-top: 110px;
} */

/* Media Queries */
@media (max-width: 1024px) {
  .footer {
    padding: 40px 20px;
  }
  .softmark {
    font-size: 4rem;
    margin-top: -7.58rem;
  }
}

@media (max-width: 787px) {
  .footer-intro{
    width: 100% !important;
  }
  .quick-sec{
    padding-top: 50px;
  }
  .contact-form-footer{
    padding-top: 50px;
  }
  .footer-bottom {
    flex-direction: column;
    align-items: center;
    /* font-size: 12px; */
    text-align: center;
  }

  .footer-bottom ul {
    text-align: center;
  }

  .footer-right {
    /* margin-left: 0; */
    text-align: center;
  }
  .footer-right p {
    text-align: center;
  }
  .softmark {
    font-size: 3rem;
    margin-top: -6.45rem;
  }
}

@media (max-width: 600px) {
  .footer-contact{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .findUs{
    padding-left: 30px;
    padding-top: 30px;
  }
  .footer-bottom {
    padding: 20px 10px;

    .footer-social {
      display: flex;
      justify-content: center;
      /* align-items: center; */
      margin-top: 20px;
      padding-top: 10px;
      flex-wrap: wrap;
    }

    .footer-social a {
      font-size: 20px;
    }

    .footer-social p {
      text-align: center;
      margin-top: 10px;
    }
    /* .footer-right p {
      text-align: center;
    } */
  }
}

@media (max-width: 377px) {
  .footer-social p {
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
  }
}
@media (max-width: 275px) {
  .footer-social p {
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
  }
}

@media (max-width: 526px) {
  .softmark {
    font-size: 2.5rem;
    margin-top: -5.8rem;
  }
}

@media (max-width: 452px) {
  .softmark {
    font-size: 2rem;
    margin-top: -5.3rem;
  }
}

@media (max-width: 378px) {
  .softmark {
    font-size: 1.6rem;
    margin-top: -4.8rem;
  }
}

@media (max-width: 376px) {
  .softmark {
    font-size: 1.9rem;
    margin-top: -5.2rem;
  }
}
@media (max-width: 325px) {
  .softmark {
    font-size: 1.5rem;
    margin-top: -4.7rem;
  }
}
