.joinus-lottie {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 350px;
  height: auto;
  overflow: hidden;
  margin-top: -30px;
}

@media (max-width: 768px) {
  .joinus-lottie {
    max-width: 300px;
  }
}

@media (max-width: 400px) {
  .joinus-lottie {
    max-width: 250px;
  }
}
