.seo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 20px;
  text-align: center;
  word-spacing: 1px;
}

.seo-p {
  text-align: justify;
  font-size: 1.2rem;
  line-height: 1.4;
  margin-bottom: 20px;
  word-spacing: 2px; 
}

.seo-h {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.seo-subheader {
  font-size: 2rem;
  font-weight: 600;
  color: black;
  margin-bottom: 30px;
  letter-spacing: 1px;
}

.button-primary,
.button-secondary {
  background-color: #0066cc;
  color: #fff;
  padding: 12px 25px;
  font-size: 1.2rem;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.button-primary {
  margin-bottom: 30px;
}

.button-secondary {
  margin-top: 10px;
}

.button-primary:hover,
.button-secondary:hover {
  background-color: #004999;
  transform: translateY(-3px);
}

.horizontal-fram-seo {
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  /* margin-bottom: 30px; */
}

.responsive-image,
.custom-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

@media screen and (max-width: 1024px) {
  .seo-p {
    font-size: 1.1rem;
    line-height: 1.5; 
  }

  .seo-h {
    font-size: 1.8rem;
    margin-bottom: 40px;
  }

  .seo-subheader {
    font-size: 1.8rem;
  }

  .button-primary,
  .button-secondary {
    font-size: 1.1rem;
    padding: 10px 22px;
  }

  .horizontal-fram-seo {
    gap: 30px;
    margin-bottom: 60px;
    flex-direction: row;
  }

  .custom-image {
    width: 400px;
    height: 18rem;
  }
}

@media screen and (max-width: 768px) {
  .seo-p {
    font-size: 1rem;
    word-spacing: 1.5px;
    margin-bottom: 15px;
  }

  .seo-h {
    font-size: 1.6rem;
  }

  .seo-subheader {
    font-size: 1.6rem;
    margin-bottom: 25px;
  }

  .horizontal-fram-seo {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
  }

  .custom-image,
  .responsive-image {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .seo {
    padding: 40px 15px;
  }

  .seo-p {
    font-size: 13px;
    word-spacing: 1px;
    line-height: 1.5;
  }

  .seo-h {
    font-size: 1.4rem;
    margin-bottom: 30px;
  }

  .seo-subheader {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }

  .button-primary,
  .button-secondary {
    font-size: 1rem;
    padding: 8px 18px;
  }

  .horizontal-fram-seo {
    gap: 20px;
    margin-bottom: 30px;
    flex-direction: column; 
  }

  .custom-image,
  .responsive-image {
    width: 100%;
    height: 14rem;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  opacity: 0; 
  animation: fadeInUp 1s forwards;
}
