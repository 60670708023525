.main-workingProcess{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* padding: 1rem; */
}
.workingCards{
    width: 50%;
    margin-bottom: 2rem;
}
.hide-img{
    width: 50%;
}

@media (max-width: 1100px) {
    .hide-img{
        display: none;
    }
    .main-workingProcess{
        flex-direction: column;

    }
    .working-card{
        width: 100% !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .workingCards{
        width: 100%;
    }

    }

@media (max-width: 787px) {
    .main-workingProcess{
        flex-direction: column;

    }
    .working-card{
        width: 90vw !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
    }
    .hide-img{
        display: none;
    }
    .working-card h4{
         text-align: center;
         font-size: 18px;
    }
    .workingCards{
        width: 100% !important;
    }
    .cards-container{
        padding: 30px !important;
    }
    }