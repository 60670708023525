.logolottie-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  height: auto;
  overflow: hidden;
}

@media (max-width: 600px) {
  .logolottie-container {
    max-width: 350px;
  }
}

@media (max-width: 400px) {
  .logolottie-container {
    max-width: 250px;
  }
}
