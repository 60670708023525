.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .scroll-to-top-button:hover {
    background-color: #155a9a;
  }
  
  .scroll-to-top-button svg {
    width: 24px;
    height: 24px;
  }
  