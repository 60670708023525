$bg-color: #f1e9da;
$link-text-color: #fff;
$button-bg-1: #f3972b;
$border-radius: 6px;

/* effect-1 styles */
.effect.effect-1 {
  text-align: center;
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: $link-text-color;
  text-transform: capitalize;
  background-color: $button-bg-1;
  font: {
    family: "Roboto", sans-serif;
    size: 18px;
  }
  padding: 20px 0px;
  width: 220px;
  border-radius: 30px;
  overflow: hidden;
  transition: all 0.2s linear 0s;
  margin-top: 30px;
  border: none;

  &:before {
    content: "\f178";
    font-family: FontAwesome;
    font-size: 15px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 40px;
    transition: all 0.2s linear 0s;
  }

  &:hover {
    text-indent: -20px;

    &:before {
      opacity: 1;
      text-indent: 0px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .effect.effect-1 {
    padding: 10px 0px;
    width: 150px;
    font-size: 12px;
    margin-top: 30px;
  }
}
