.card-heading-service {
  text-align: center;
  font-size: 1.5rem;
  font-family: "Lucida Sans", "Lucida Grande",
    "Lucida Sans Unicode", sans-serif;
  padding-top: 30px;
}
.animation {
  transform: scaleX(-1);
}

.card-heading-service .heading-underline-service {
  position: relative;
  display: inline-block;
}

.card-heading-service
  .heading-underline-service::after {
  content: "";
  margin-top: 50px;
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 50%;
  height: 3px; /* Adjust the height as needed */
  background-color: rgb(220, 166, 66);
  transition: width 0.5s ease;
  animation: underline-animation 3s ease forwards;
}

@keyframes underline-animation {
  0% {
    width: 0;
  }
  100% {
    width: 50%;
  }
}

.card-title,
.card-desc {
  transition: filter 0.4s ease-in-out;
}

.card:hover .card-title,
.card:hover .card-desc {
  filter: blur(10px);
}


.cards-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
}

.card {
  max-width: 100%; 
  margin: 0 auto; 
}

.serviceCards{
  flex-wrap: wrap !important;
}

@media (max-width: 381px) {
  .cards-container .card {
    max-width: 100%; /* Utilize full width for small screens */
    margin: 0; /* Remove extra margins */
    /* padding-left: 50px;
    padding-right: 50px; */
  }
}
